import React, {
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import clsx from "clsx";
import MUITable, { TableProps as MUITableProps } from "@material-ui/core/Table";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import BodyRow, { TableRow } from "./TableRow";
import TableCell, { Props as TableCellProps } from "./TableCell";
import useSelection from "./useSelection";
import Checkbox from "../Checkbox";
import InfiniteScroll, { InfiniteScrollOptions } from "./InfiniteScroll";
import { withStyles, createStyles } from "@material-ui/core/styles";

const StyledTable = withStyles((theme: CustomTheme.RootObject) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
  })
)(MUITable);

export interface TableRef {
  resetSelectedRows: () => void;
}

export interface ColumnType {
  title?: string;
  key: string;
  sort?: boolean;
  copy?: boolean;
  cellOptions?: TableCellProps | {};
  render?: (
    value: any,
    row: any,
    index: number,
    ref: React.MutableRefObject<HTMLTableRowElement | null>
  ) => React.ReactNode;
}

interface RowSelection<T> {
  hideSelectAll?: boolean;
  onChange?: (selectedRowKeys: string[]) => void;
  onSelect?: (record?: T, selected?: boolean, selectedRows?: string[]) => void;
  onSelectAll?: (selected?: boolean) => void;
}

export interface ExpandableConfig {
  highlightRow?: boolean;
  expandIcon?: (expanded: boolean) => React.ReactNode;
  rowExpandable?: (row: any) => boolean;
  expandedRowRender?: (row: any) => React.ReactNode;
}

export interface TableProps<T> extends MUITableProps {
  columns: ColumnType[];
  dataSource?: T[];
  rowSelection?: RowSelection<T>;
  expandable?: ExpandableConfig;
  hideHeader?: boolean;
  infiniteScroll?: InfiniteScrollOptions;
  rowKey?: string;
  hideSorting?: boolean;
  hideColumns?: string[];
  onRequestSort?: (field: string, order: "desc" | "asc") => void;
}

const Table = (
  {
    rowKey,
    columns,
    dataSource,
    rowSelection,
    expandable,
    hideHeader,
    infiniteScroll,
    hideSorting,
    onRequestSort,
    hideColumns,
    ...tableProps
  }: TableProps<any>,
  ref: any
) => {
  const [order, setOrder] = useState<"desc" | "asc" | undefined>();
  const [tableColumns, setTableColumns] = useState<ColumnType[]>([]);
  const [orderBy, setOrderBy] = useState<string | undefined>();
  const { onChange, onSelect, onSelectAll, hideSelectAll } = rowSelection || {};
  const {
    selectedRows,
    selectedAll,
    indeterminateAllCb,
    setSelectedRow,
    setSelectAll,
    resetSelectedRows,
  } = useSelection(dataSource || [], rowKey);

  useImperativeHandle(ref, () => ({
    resetSelectedRows,
  }));

  const handleRequestSort = useCallback(
    (
      _event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
      property: string
    ) => {
      const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);
      onRequestSort && onRequestSort(property, newOrder);
    },
    [onRequestSort, orderBy, order]
  );

  const renderHeadCells = useCallback(() => {
    return tableColumns
      ? tableColumns.map((c, i) => (
          <TableCell key={i} {...c.cellOptions}>
            {c.sort && !hideSorting ? (
              <TableSortLabel
                active={orderBy === c.key}
                direction={orderBy === c.key ? order : "asc"}
                onClick={(e) => handleRequestSort(e, c.key)}
              >
                {c.title}
              </TableSortLabel>
            ) : (
              c.title
            )}
          </TableCell>
        ))
      : null;
  }, [tableColumns, orderBy, order, handleRequestSort, hideSorting]);

  const handleSelectAll = useCallback(
    (checked) => {
      if (!checked && !indeterminateAllCb) {
        setSelectAll(false);
        resetSelectedRows();
      }

      if(indeterminateAllCb) {
        setSelectAll(true);
        onSelectAll && onSelectAll(true);
        return;
      }
      setSelectAll(checked);
      onSelectAll && onSelectAll(checked);
    },
    [dataSource, setSelectAll, onSelectAll, resetSelectedRows, indeterminateAllCb]
  );

  const handleSelect = useCallback(
    (key, checked, data) => {
      if (onSelect && selectedRows) {
        onSelect(data, checked, selectedRows);
      }
      setSelectedRow(key, checked);
    },
    [dataSource, selectedRows, setSelectedRow, onSelect, setSelectAll]
  );

  useEffect(() => {
    setTableColumns(
      hideColumns
        ? columns.filter((c) => !hideColumns.includes(c.key))
        : columns
    );
  }, [columns, hideColumns]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedRows || []);
    }
  }, [selectedRows]);

  return (
    <InfiniteScroll infiniteScrollOptions={infiniteScroll}>
      <StyledTable {...tableProps}>
        <TableHead className={clsx({ hidden: hideHeader })}>
          <>
            <TableRow>
              {!!expandable && (
                <TableCell width={10} key="expandable" align="center" />
              )}
              {!!rowSelection && (
                <TableCell width={10} key="all-selection" align="center">
                  {!hideSelectAll && (
                    <Checkbox
                      checked={selectedAll}
                      indeterminate={indeterminateAllCb}
                      size="small"
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                  )}
                </TableCell>
              )}
              {tableColumns && renderHeadCells()}
            </TableRow>
          </>
        </TableHead>
        <TableBody>
          {dataSource &&
            tableColumns &&
            dataSource.map((data: { [key: string]: any }, index) => {
              const key = rowKey ? data[rowKey] : index;
              return (
                <BodyRow
                  key={key}
                  {...{
                    index,
                    data,
                    isRowSelection: !!rowSelection,
                    selected: selectedRows.includes(key),
                    handleSelectRow: (checked) =>
                      handleSelect(key, checked, data),
                    columns: tableColumns,
                    expandable,
                  }}
                />
              );
            })}
        </TableBody>
      </StyledTable>
    </InfiniteScroll>
  );
};

export default forwardRef<any, TableProps<any>>(Table);
